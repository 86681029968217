<template>
  <ul v-if="errorArray.length" class="errorlist" :style="errorStyling.ul">
    <li
      v-for="error in errorArray"
      :key="error"
      class="error"
      :style="errorStyling.li"
    >
      <button v-if="useCloseButton" class="close">Close</button>
      {{ label }}{{ error }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ErrorList',
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    errorStyling: {
      type: Object,
      required: false,
      default() {
        return {
          ul: {},
          li: {},
        }
      },
    },
    suppressLabel: {
      type: Boolean,
      default: false,
      required: false,
    },
    useCloseButton: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    label() {
      if (this.suppressLabel) {
        return ''
      }
      return 'Error: '
    },
    errorArray() {
      if (typeof this.errors === 'string') {
        return [this.errors]
      }
      if (Array.isArray(this.errors)) {
        return this.errors
      }
      return []
    },
  },
}
</script>
